.carousel-container {
    overflow: hidden;
    position: relative;
    height: 30%;
    max-height: 400px;
    min-height: 300px;
    background-color: rgb(41, 40, 40);
    width: 100%;
  }
  
  .carousel-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 200%;
    &:hover {
      transform: scale(0.9);
      box-shadow: 5px 5px 8px rgba(222, 222, 222, 0.362),
        10px 10px 8px rgba(152, 133, 133, 0.392),
        15px 15px 8px rgba(139, 154, 139, 0.303);
    }
    /* border-radius: 20%; */
    background: rgb(41, 40, 40);
  }
  
  .carousel-card > img {
    height: 95%;
    width: 150%;
  }

  .carousel-track {
    display: flex;
    position: absolute;
    left: 0;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 200%;
    height: 100%;
    animation: slide 40s linear infinite;
    &:hover {
        animation-play-state: paused;
    }
  }

  @keyframes slide {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }